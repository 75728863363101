import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
import { IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { useSnackbar } from 'notistack';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { usePathname } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

//
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import { SettingsButton, HeaderShadow, LoginButton, AccountPopover, NotificationsPopover } from '../_common';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const { user, login, authenticated } = useAuthContext();

  const [cookies, setCookie, removeCookie] = useCookies(['auth']);

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const loading = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const pathname = usePathname();

  const isBookingRoute = pathname.includes("booking") || pathname.includes("contact");

  const handleLogin = async () => {
    loading.onTrue();
    const loginResponse = await login();
    console.log('loginResponse', loginResponse);
    if (loginResponse.success) {
      enqueueSnackbar("Login success", { autoHideDuration: 500 });
    } else {
      enqueueSnackbar(loginResponse?.error ?? "Something went wrong", { variant: "error" });
    }
    loading.onFalse();
  }

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Stack direction="row" spacing={1.5} alignItems="center" flexGrow={1}>
            {/* <Badge
            sx={{
              [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: -16,
              },
            }}
            badgeContent={
              <Link
                href={paths.changelog}
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{ ml: 1 }}
              >
                <Label color="info" sx={{ textTransform: 'unset', height: 22, px: 0.5 }}>
                  v5.4.0
                </Label>
              </Link>
            }
          >
          </Badge> */}
            {/* <IconButton
            color="inherit"
            onClick={() => window.history.back()}
          >
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton> */}
            <Logo />

            1.1.14

            <Box sx={{ flexGrow: 1 }} />

            <IconButton onClick={handleReload} color="primary"><Iconify width={28} icon="codicon:refresh" /></IconButton>

            {/* <NotificationsPopover /> */}

            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
              {!authenticated ?
                <LoadingButton loading={loading.value} variant="text" size="small" color="primary"
                  onClick={handleLogin}
                >
                  LOGIN
                </LoadingButton> :
                <AccountPopover />}
            </Stack>
          </Stack>
        </Container>
      </Toolbar>

      {/* <Typography color="#00000010" variant="caption">{cookies.puid}<br />{cookies.grantToken}</Typography> */}

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
